import React from 'react';
import { styled, media } from 'styles';
import { MathjaxContent } from 'modules/shared/components/generic';
import { useBookEditionContext } from '../providers/BookEditionProvider';

export default function ExerciseStatement({ termSearched = '' }) {
  const { bookExercise } = useBookEditionContext();

  return (
    <Container>
      {termSearched !== '' && (
        <>
          <BodyHeader>Você pesquisou por: </BodyHeader>
          <div style={{ fontStyle: 'italic', marginBottom: '30px' }}>
            <MathjaxContent html={termSearched} />
          </div>
        </>
      )}

      <BodyHeader>Enunciado </BodyHeader>
      <div style={{ fontStyle: 'italic' }}>
        <MathjaxContent html={bookExercise?.lightBody} />
      </div>
      <hr style={{ marginBottom: '40px' }} />
    </Container>
  );
}

const Container = styled.div`
  padding: 0 30px;

  p,
  li {
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media ${media.mobile} {
    padding: 0 10px;
  }
`;

const BodyHeader = styled.h2`
  color: #36aaad;
  font-size: 1.5em;
  font-family: 'Droid Serif', serif;
  font-weight: bold;
  margin: 40px 0;
`;
